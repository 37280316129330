import type { PropsWithChildren } from 'react'

import Section from '../Section'

function ProductGalleryHeading({ children }: PropsWithChildren) {
  return (
    <Section className="layout__content">
      <h1 className="title-section text__title-section">{children}</h1>
    </Section>
  )
}

export default ProductGalleryHeading
